import { z } from "zod";

export const NotificationEmailSchema = z
  .object({
    email:  z.string().min(1, "Required"),
    barID: z.string().optional(),
    partyRepresented: z.string().min(1, "Required"),
    orderingParty: z.string().min(1, "Required"),
  });

export type NotificationEmailInput = z.infer<typeof NotificationEmailSchema>;
