import React, { useState } from "react";
//import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, InputAdornment, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import OrderListTable from "../components/order/OrderListTable";
//import { placeLog } from "../redux/actions/log.action";

export default function IndexPage() {
  //const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  const [search, setSearch] = useState("");

  const ClickNewOrder = () => {
    navigate("/order/save");
    /*
    navigate(
      "/order/save", 
      {state: {
        updateRecord: {
          orderNumber: "draft"
        }
      }}
    );
    */
  };

  const ClickUpdateOrder = (record:any) => {
    navigate("/order/save", {state: {updateRecord: record}});
  };

  return (
    <div className="flex flex-col items-center w-full">
      <main className="container flex flex-col w-full pt-28">
        {
          user && (
            <div className="px-8 pb-4">
              <div className="flex justify-between w-full">
                <TextField
                  variant="outlined"
                  className="w-80"
                  placeholder="Search items..."
                  value={search}
                  onChange={({ target: { value } }) => setSearch(value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  variant="contained"
                  className="px-4 py-3 bg-primary font-bold"
                  onClick={ClickNewOrder}
                >
                  <AddIcon />
                  <p className="ml-2">new order</p>
                </Button>
              </div>
              <OrderListTable search={search} updateFunction={ClickUpdateOrder} />
            </div>
          )
        }
        {
          user === null && (
            <>
              <div className="flex flex-col gap-12 w-full px-24">
                <p>DWQ.Legal Does DWQs & Subpoenas Right.</p>
                <div className="flex flex-col gap-8 relative">
                  <Button
                    variant="contained"
                    className="bg-primary font-bold mx-auto"
                    size="large"
                    sx={{ textTransform: "none", borderRadius: "16px" }}
                  >
                    <p>What`s a DWQ?</p>
                  </Button>
                  <Button
                    variant="contained"
                    className="bg-primary font-bold mx-auto"
                    size="large"
                    sx={{ textTransform: "none", borderRadius: "16px" }}
                  >
                    <p>What`s a Subpoena?</p>
                  </Button>
                </div>
                {/* <Slide
                  direction="right"
                  in={true}
                  timeout={1000}
                  mountOnEnter
                  unmountOnExit
                >
                  <img
                    className="h-auto w-[50%] left-0 bottom-0"
                    src="assets/images/banners/lawyer.png"
                    alt="Lawyer Image"
                    loading="lazy"
                  />
                </Slide>
                <div className="flex flex-col gap-8 relative">
                  <Slide
                    direction="left"
                    in={true}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <p className="text-xl md:text-4xl lg:text-6xl -ml-12 lg:-ml-72">Protecting your rights</p>
                      <p className="text-xl mt-4 md:text-4xl lg:text-6xl -ml-6 lg:-ml-36">Securing your future</p>
                    </div>
                  </Slide>
                  <Zoom
                    in={true}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                    style={{ transitionDelay: "500ms" }}
                  >
                    <div className="flex-grow px-8">
                      <p className="hidden md:block text-md lg:text-2xl my-2 lg:my-8 relative z-[10]">
                        At Leganrend, protect your rights and secure your future. Expert legal guidance and representation by our dedicated attorneys. Favorable outcomes through our experience and dedication. Contact us today for a consultation.
                      </p>
                      <div className="flex flex-col w-full xl:w-[60%] gap-8 relative">
                        <Button
                          variant="contained"
                          className="bg-primary font-bold"
                          size="large"
                          sx={{textTransform : "none", zIndex: 10}}
                        >
                          <p>What`s a DWQ?</p>
                        </Button>
                        <Button
                          variant="contained"
                          className="bg-primary font-bold"
                          size="large"
                          sx={{textTransform : "none", zIndex: 10}}
                        >
                          <p>What`s a Subpoena?</p>
                        </Button>
                      </div>
                      
                    </div>
                  </Zoom>
                </div> */}
              </div>
            </>
          )
        }
      </main>
    </div>
  );
}
