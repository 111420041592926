import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserLicenseList } from "../../redux/actions/license.action";
import parse from "html-react-parser";

type Props = {
  open: boolean;
  onClose: any;
}

const UserLicenseDialog = (props: Props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();

  const { list, total, isLoading } = useSelector(({ licenseReducer }) => licenseReducer);

  useEffect(() => {
    dispatch(getUserLicenseList());
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent>
        {
          !isLoading && !!total && 
            list
              .filter((item: any) => item.active == true)
              .map((license: any) => (
                parse(license.content)
              ))
        }
      </DialogContent>
    </Dialog>
  );
};

export default UserLicenseDialog;