import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSplash from "../pages/LoadingSplash";
import { loginWithToken } from "../redux/actions/cognitouser.action";

export default function AuthorizedComponent({ children }: { children: React.ReactNode }) {

  const dispatch = useDispatch();
  const { isOnAuthorizing, user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  React.useEffect(() => {
    const accessToken: string | null = localStorage.getItem("access-token");
    if (accessToken) {
      dispatch(loginWithToken());
    }
  }, [dispatch]);

  if(isOnAuthorizing)
    return <LoadingSplash />;
  else {
    if(user !==  null) {
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      );
    }
  }

  return isOnAuthorizing ? <LoadingSplash/> : <React.Fragment>{children}</React.Fragment>;
}

AuthorizedComponent.displayName="authorizedComponent";