import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { 
  TextField,
  Button
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import LabelInputWrapper from "../form/LabelnputWrapper";
import SelectInput from "../custom-input/SelectInput";

import { getNotificationemailList } from "../../redux/actions/notificationemail.action";
import { NotificationEmailInput, NotificationEmailSchema } from "../../lib/validations/notificationemail.schema";

import { representedPartyCategory } from "../../config/static-data";

interface NotificationEmailItemDlgProps {
  open: boolean;
  onClose: () => void;
  data: any;
  saveFunction: (data:any) => void;
  updateFunction: (data:any)=>void;
}

const NotificationEmailItemAddDialog = (props: NotificationEmailItemDlgProps) => {
  const { open, onClose, data, saveFunction, updateFunction } = props;

  const [dlgTitle, setDlgTitle] = useState("Add a notification email item");
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  const { list: notificationemailList } = useSelector(({ notificationemailReducer }) => notificationemailReducer);

  const [tmpInputEmail, setTmpInputEmail] = useState("");
  const [tmpInputBarID, setTmpInputBarID] = useState("");
  const [tmpInputPartyRepresented, setTmpInputPartyRepresented] = useState("");
  const [tmpInputOrderingParty, setTmpInputOrderingParty] = useState("");

  const dispatch = useDispatch();

  // Destructure form hooks for the input
  const methods = useForm<NotificationEmailInput>({ resolver: zodResolver(NotificationEmailSchema) });
  const { register, handleSubmit, reset, formState, setValue } = methods;
  const { errors } = formState;

  useEffect(() => {
    dispatch(getNotificationemailList());
  }, [dispatch]);

  useEffect(()=>{
    // console.log(notificationemailList);
    // console.log("user", JSON.stringify(user), user?.email);
    reset();
    if (data.email.length < 1 && data.barID.length < 1 && data.partyRepresented.length <1 && data.orderingParty <1) {
      setDlgTitle("Add a notification email item");
      setTmpInputEmail("");
      setTmpInputBarID("");
      //setTmpInputPartyRepresented(user?.firstName + " " + user?.lastName);
      setTmpInputPartyRepresented("");
      setTmpInputOrderingParty( user?.email);
      setValue("email", "");
      setValue("barID", "");
      //setValue("partyRepresented", user?.firstName + " " + user?.lastName);
      setValue("partyRepresented", "");
      setValue("orderingParty", user?.email);
    } else {
      setDlgTitle("Update a notification email item");
      setTmpInputEmail(data.email);
      setTmpInputBarID(data.barID);
      setTmpInputPartyRepresented(data.partyRepresented);
      setTmpInputOrderingParty(data.orderingParty);
      setValue("email", data.email);
      setValue("barID", data.barID);
      setValue("partyRepresented", data.partyRepresented);
      setValue("orderingParty", data.orderingParty);
    }
  }, [data, open]);

  

  // Datas from Redux
  //const { list:listOfBars } = useSelector(({ barsReducer }) => barsReducer);

  // Open confirmation dialog when submit
  const onSubmit = (saveData: NotificationEmailInput) => {
    if (data.email.length < 1 && data.barID.length < 1 && data.partyRepresented.length <1 && data.orderingParty <1) {
      saveFunction(saveData);
    } else {
      updateFunction(saveData);
    }
    reset();
    onClose();
  };

  const onCancel = () => {
    const confirmClose = window.confirm("Are you sure you want to cancel?"); 
    if (confirmClose) { 
      reset();
      onClose(); 
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{dlgTitle}</DialogTitle>
      <DialogContent>
        <main className="flex flex-col justify-center w-full">
          <form className="container flex flex-col w-full pl-8 pr-24 py-4 fade-up-anim anim-200" onSubmit={handleSubmit(onSubmit)}>
            <LabelInputWrapper label="Email:" className="w-full">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                className="w-full"
                options={notificationemailList.map((emailAddr:string) => emailAddr)}
                value={tmpInputEmail}
                renderInput={(params) => 
                  <TextField
                    {...params}
                    variant="standard"
                    {...register("email")}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    value={tmpInputEmail}
                    onChange={(e) => setTmpInputEmail(e.target.value)}
                  />
                }
              />
              
            </LabelInputWrapper>
            <LabelInputWrapper label="Bar Number:" className="w-full">
              <TextField
                variant="standard"
                className="w-full"
                {...register("barID")}
                error={errors.barID ? true : false}
                helperText={errors.barID?.message}
                value={tmpInputBarID}
                onChange={(e) => setTmpInputBarID(e.target.value)}
              />
            </LabelInputWrapper>
            
            <LabelInputWrapper label="Party Represented:" className="w-full">
              <SelectInput
                variant="standard"
                className="w-full"
                handler={register("partyRepresented")}
                error={errors.partyRepresented ? true : false}
                helperText={errors.partyRepresented?.message}
                options={{
                  data: representedPartyCategory,
                  labelKey: "label",
                  valueKey: "value",
                  value: tmpInputPartyRepresented
                }}
                onChange={(e) => { setTmpInputPartyRepresented(e.target.value); }}
              />
            </LabelInputWrapper>
            <LabelInputWrapper label="Ordering Party:" className="w-full hidden">
              <TextField
                variant="standard"
                className="w-full"
                {...register("orderingParty")}
                error={errors.orderingParty ? true : false}
                helperText={errors.orderingParty?.message}
                value={tmpInputOrderingParty}
                onChange={(e) => setTmpInputOrderingParty(e.target.value)}
              />
            </LabelInputWrapper>

            <div className="flex justify-center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="w-36 h-8 mt-8 ml-4 bg-primary text-md tracking-widest"
              >
                {"Save"}
              </Button>

              <Button
                variant="contained"
                color="error"
                className="w-36 h-8 mt-8 ml-4 bg-red-500 text-md tracking-widest"
                onClick={onCancel}
              >
                {"Cancel"}
              </Button>
            </div>
          </form>
        </main>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationEmailItemAddDialog;


/*
<TextField
                variant="standard"
                className="w-full"
                {...register("partyRepresented")}
                error={errors.partyRepresented ? true : false}
                helperText={errors.partyRepresented?.message}
                value={tmpInputPartyRepresented}
                onChange={(e) => setTmpInputPartyRepresented(e.target.value)}
              />
              */