import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, Avatar, styled } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ButtonLoader from "../../components/shared/ButtonLoader";

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.palette.secondary.main};
        color: ${theme.palette.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

export default function ActionAreaCard(props:any) {
  // console.log(props);
  return (
    <Card sx={{ width: 345, height:200, border:"white", margin:"8px" }}>
      <CardActionArea sx={{ px: 1 }} onClick={props.onClick} disabled={props.disabled}>
        <CardContent sx={{display:"flex", justifyContent: "center", alignItems:"center", width: "100%", height:200}} >
          <AvatarAddWrapper>
            {props.disabled ? <ButtonLoader /> : <AddTwoToneIcon fontSize="large" />}
          </AvatarAddWrapper>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}