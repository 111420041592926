const adminRoleConfig = {
  superadministrator: "superadministrator",
  administrator:"administrator"
};

const userRoleConfig = {
  lawyer: "lawyer"
};

export const roleConfig = {
  admin: adminRoleConfig,
  user: userRoleConfig
};