// Cognitouser action types
export const COGNITOUSER_CHCKIFEMAILVERIFIED_REQUEST = "[COGNITOUSER] CHECK IF EMAIL VERIFIED REQUEST";
export const COGNITOUSER_CHCKIFEMAILVERIFIED_SUCCESS = "[COGNITOUSER] CHECK IF EMAIL VERIFIED SUCCESS";
export const COGNITOUSER_CHCKIFEMAILVERIFIED_FAILURE = "[COGNITOUSER] CHECK IF EMAIL VERIFIED FAILURE";

export const COGNITOUSER_VERIFY_EMAIL_REQUEST = "[COGNITOUSER] VERIFY EMAIL REQUEST";
export const COGNITOUSER_VERIFY_EMAIL_SUCCESS = "[COGNITOUSER] VERIFY EMAIL SUCCESS";
export const COGNITOUSER_VERIFY_EMAIL_FAILURE = "[COGNITOUSER] VERIFY EMAIL FAILURE";

export const COGNITOUSER_GETCONFIRMATIONSTATUS_REQUEST = "[COGNITOUSER] GET CONFIRMATIONSTATUS REQUEST";
export const COGNITOUSER_GETCONFIRMATIONSTATUS_SUCCESS = "[COGNITOUSER] GET CONFIRMATIONSTATUS SUCCESS";
export const COGNITOUSER_GETCONFIRMATIONSTATUS_FAILURE = "[COGNITOUSER] GET CONFIRMATIONSTATUS FAILURE";

export const COGNITOUSER_GET_AGREEMENT_STATUS_REQUEST = "[COGNITOUSER] GET AGREEMENT_STATUS REQUEST";
export const COGNITOUSER_GET_AGREEMENT_STATUS_SUCCESS = "[COGNITOUSER] GET AGREEMENT_STATUS SUCCESS";
export const COGNITOUSER_GET_AGREEMENT_STATUS_FAILURE = "[COGNITOUSER] GET AGREEMENT_STATUS FAILURE";

export const COGNITOUSER_UPDATEINFO_REQUEST = "[COGNITOUSER] UPDATEINFO REQUEST";
export const COGNITOUSER_UPDATEINFO_SUCCESS = "[COGNITOUSER] UPDATEINFO SUCCESS";
export const COGNITOUSER_UPDATEINFO_FAILURE = "[COGNITOUSER] UPDATEINFO FAILURE";

export const COGNITOUSER_REGISTER_REQUEST = "[COGNITOUSER] REGISTER REQUEST";
export const COGNITOUSER_REGISTER_SUCCESS = "[COGNITOUSER] REGISTER SUCCESS";
export const COGNITOUSER_REGISTER_FAILURE = "[COGNITOUSER] REGISTER FAILURE";

export const COGNITOUSER_LOGIN_REQUEST = "[COGNITOUSER] LOGIN REQUEST";
export const COGNITOUSER_LOGIN_SUCCESS = "[COGNITOUSER] LOGIN SUCCESS";
export const COGNITOUSER_LOGIN_FAILURE = "[COGNITOUSER] LOGIN FAILURE";

export const COGNITOUSER_LOGIN_WITH_TOKEN_REQUEST = "[COGNITOUSER] LOGIN WITH TOKEN REQUEST";
export const COGNITOUSER_LOGIN_WITH_TOKEN_SUCCESS = "[COGNITOUSER] LOGIN WITH TOKEN SUCCESS";
export const COGNITOUSER_LOGIN_WITH_TOKEN_FAILURE = "[COGNITOUSER] LOGIN WITH TOKEN FAILURE";

export const COGNITOUSER_LOGOUT = "[COGNITOUSER] LOGOUT";

export const COGNITOUSER_GETPAYMENTCUSTOMERID_REQUEST = "[COGNITOUSER] GET PAYMENT CUSTOMERID REQUEST";
export const COGNITOUSER_GETPAYMENTCUSTOMERID_SUCCESS = "[COGNITOUSER] GET PAYMENT CUSTOMERID SUCCESS";
export const COGNITOUSER_GETPAYMENTCUSTOMERID_FAILURE = "[COGNITOUSER] GET PAYMENT CUSTOMERID FAILURE";
export const COGNITOUSER_GETPAYMENTLIST_REQUEST = "[COGNITOUSER] GET PAYMENT LIST REQUEST";
export const COGNITOUSER_GETPAYMENTLIST_SUCCESS = "[COGNITOUSER] GET PAYMENT LIST SUCCESS";
export const COGNITOUSER_GETPAYMENTLIST_FAILURE = "[COGNITOUSER] GET PAYMENT LIST FAILURE";
export const COGNITOUSER_ADDPAYMENT_REQUEST = "[COGNITOUSER] ADD PAYMENT REQUEST";
export const COGNITOUSER_ADDPAYMENT_SUCCESS = "[COGNITOUSER] ADD PAYMENT SUCCESS";
export const COGNITOUSER_ADDPAYMENT_FAILURE = "[COGNITOUSER] ADD PAYMENT FAILURE";
export const COGNITOUSER_DELETEPAYMENT_REQUEST = "[COGNITOUSER] DELETE PAYMENT REQUEST";
export const COGNITOUSER_DELETEPAYMENT_SUCCESS = "[COGNITOUSER] DELETE PAYMENT SUCCESS";
export const COGNITOUSER_DELETEPAYMENT_FAILURE = "[COGNITOUSER] DELETE PAYMENT FAILURE";
export const COGNITOUSER_SETPRIMARYPAYMENT_REQUEST = "[COGNITOUSER] SET PRIMARY PAYMENT REQUEST";
export const COGNITOUSER_SETPRIMARYPAYMENT_SUCCESS = "[COGNITOUSER] SET PRIMARY PAYMENT SUCCESS";
export const COGNITOUSER_SETPRIMARYPAYMENT_FAILURE = "[COGNITOUSER] SET PRIMARY PAYMENT FAILURE";
export const COGNITOUSER_PAYMENTVERIFICATION_REQUEST = "[COGNITOUSER] PAYMENT VERIFICATION REQUEST";
export const COGNITOUSER_PAYMENTVERIFICATION_SUCCESS = "[COGNITOUSER] PAYMENT VERIFICATION SUCCESS";
export const COGNITOUSER_PAYMENTVERIFICATION_FAILURE = "[COGNITOUSER] PAYMENT VERIFICATION FAILURE";
export const COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_REQUEST = "[COGNITOUSER] CHECK PAYMENT VERIFICATION VALUE REQUEST";
export const COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_SUCCESS = "[COGNITOUSER] CHECK PAYMENT VERIFICATION VALUE SUCCESS";
export const COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_FAILURE = "[COGNITOUSER] CHECK PAYMENT VERIFICATION VALUE FAILURE";

// User auth action types
export const USER_REGISTER_REQUEST = "[USER] REGISTER REQUEST";
export const USER_REGISTER_SUCCESS = "[USER] REGISTER SUCCESS";
export const USER_REGISTER_FAILURE = "[USER] REGISTER FAILURE";

export const USER_LOGIN_REQUEST = "[USER] LOGIN REQUEST";
export const USER_LOGIN_SUCCESS = "[USER] LOGIN SUCCESS";
export const USER_LOGIN_FAILURE = "[USER] LOGIN FAILURE";

export const USER_CHCKIFREGISTERED_REQUEST = "[USER] CHECK IF REGISTERED REQUEST";
export const USER_CHCKIFREGISTERED_SUCCESS = "[USER] CHECK IF REGISTERED SUCCESS";
export const USER_CHCKIFREGISTERED_FAILURE = "[USER] CHECK IF REGISTERED FAILURE";

export const USER_CHCKIFEMAILVERIFIED_REQUEST = "[USER] CHECK IF EMAIL VERIFIED REQUEST";
export const USER_CHCKIFEMAILVERIFIED_SUCCESS = "[USER] CHECK IF EMAIL VERIFIED SUCCESS";
export const USER_CHCKIFEMAILVERIFIED_FAILURE = "[USER] CHECK IF EMAIL VERIFIED FAILURE";

export const LOGIN_WITH_TOKEN_REQUEST = "[USER] LOGIN WITH TOKEN REQUEST";
export const LOGIN_WITH_TOKEN_SUCCESS = "[USER] LOGIN WITH TOKEN SUCCESS";
export const LOGIN_WITH_TOKEN_FAILURE = "[USER] LOGIN WITH TOKEN FAILURE";

export const USER_LOGOUT = "[USER] LOGOUT";

export const VERIFY_EMAIL_REQUEST = "[USER] VERIFY EMAIL REQUEST";
export const VERIFY_EMAIL_SUCCESS = "[USER] VERIFY EMAIL SUCCESS";
export const VERIFY_EMAIL_FAILURE = "[USER] VERIFY EMAIL FAILURE";

export const RESEND_EMAIL_REQUEST = "[USER] RESEND EMAIL REQUEST";
export const RESEND_EMAIL_SUCCESS = "[USER] RESEND EMAIL SUCCESS";
export const RESEND_EMAIL_FAILURE = "[USER] RESEND EMAIL FAILURE";

export const FORGOT_PASSWORD_EMAIL_REQUEST = "[USER] [FORGOT_PASSWORD] EMAIL_REQUEST";
export const FORGOT_PASSWORD_EMAIL_SUCCESS = "[USER] [FORGOT_PASSWORD] EMAIL_SUCCESS";
export const FORGOT_PASSWORD_EMAIL_FAILURE = "[USER] [FORGOT_PASSWORD] EMAIL_FAILURE";

export const RESET_PASSWORD_REQUEST = "[USER] RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "[USER] RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "[USER] RESET_PASSWORD_FAILURE";


// Order action types
export const CREATE_ORDER_REQUEST = "[ORDER] CREATE REQUEST";
export const CREATE_ORDER_SUCCESS = "[ORDER] CREATE SUCCESS";
export const CREATE_ORDER_FAILURE = "[ORDER] CREATE FAILURE";

export const GET_ORDER_LIST_REQUEST = "[ORDER] GET LIST REQUEST";
export const GET_ORDER_LIST_SUCCESS = "[ORDER] GET LIST SUCCESS";
export const GET_ORDER_LIST_FAILURE = "[ORDER] GET LIST FAILURE";

export const GET_ORDER_DETAIL_REQUEST = "[ORDER] GET DETAIL REQUEST";
export const GET_ORDER_DETAIL_SUCCESS = "[ORDER] GET DETAIL SUCCESS";
export const GET_ORDER_DETAIL_FAILURE = "[ORDER] GET DETAIL FAILURE";

export const GET_DRAFT_ORDER_REQUEST = "[ORDER] GET DRAFT REQUEST";
export const GET_DRAFT_ORDER_SUCCESS = "[ORDER] GET DRAFT SUCCESS";
export const GET_DRAFT_ORDER_FAILURE = "[ORDER] GET DRAFT FAILURE";

export const DELETE_DRAFT_ORDER_REQUEST = "[ORDER] DELETE DRAFT REQUEST";
export const DELETE_DRAFT_ORDER_SUCCESS = "[ORDER] DELETE DRAFT SUCCESS";
export const DELETE_DRAFT_ORDER_FAILURE = "[ORDER] DELETE DRAFT FAILURE";

export const CREATE_DRAFT_ORDER_REQUEST = "[ORDER] CREATE DRAFT REQUEST";
export const CREATE_DRAFT_ORDER_SUCCESS = "[ORDER] CREATE DRAFT SUCCESS";
export const CREATE_DRAFT_ORDER_FAILURE = "[ORDER] CREATE DRAFT FAILURE";

export const UPDATE_ORDERSTATUS_REQUEST = "[ORDER] UPDATE STATUS REQUEST";
export const UPDATE_ORDERSTATUS_SUCCESS = "[ORDER] UPDATE STATUS SUCCESS";
export const UPDATE_ORDERSTATUS_FAILURE = "[ORDER] UPDATE STATUS FAILURE";

// Court action types
export const GET_COURT_LIST_REQUEST = "[COURT] GET LIST REQUEST";
export const GET_COURT_LIST_SUCCESS = "[COURT] GET LIST SUCCESS";
export const GET_COURT_LIST_FAILURE = "[COURT] GET LIST FAILURE";

// CellphoneCarrier action types
export const GET_CELLPHONE_CARRIER_LIST_REQUEST = "[CELLPHONECARRIER] GET LIST REQUEST";
export const GET_CELLPHONE_CARRIER_LIST_SUCCESS = "[CELLPHONECARRIER] GET LIST SUCCESS";
export const GET_CELLPHONE_CARRIER_LIST_FAILURE = "[CELLPHONECARRIER] GET LIST FAILURE";

// Log action types
export const LOG_REGISTER_REQUEST = "[LOG] REGISTER REQUEST";
export const LOG_REGISTER_SUCCESS = "[LOG] REGISTER SUCCESS";
export const LOG_REGISTER_FAILURE = "[LOG] REGISTER FAILURE";

// Bars action types
export const CREATE_BAR_REQUEST = "[BAR] CREATE REQUEST";
export const CREATE_BAR_SUCCESS = "[BAR] CREATE SUCCESS";
export const CREATE_BAR_FAILURE = "[BAR] CREATE FAILURE";

export const GET_BAR_LIST_REQUEST = "[BAR] GET LIST REQUEST";
export const GET_BAR_LIST_SUCCESS = "[BAR] GET LIST SUCCESS";
export const GET_BAR_LIST_FAILURE = "[BAR] GET LIST FAILURE";

// Notification email action types
export const GET_NOTIFICATIONEMAIL_LIST_REQUEST = "[NOTIFICATIONEMAIL] GET LIST REQUEST";
export const GET_NOTIFICATIONEMAIL_LIST_SUCCESS = "[NOTIFICATIONEMAIL] GET LIST SUCCESS";
export const GET_NOTIFICATIONEMAIL_LIST_FAILURE = "[NOTIFICATIONEMAIL] GET LIST FAILURE";
export const ADD_NOTIFICATION_EMAIL_TOLIST_REQUEST = "[NOTIFICATIONEMAIL] ADD REQUEST";
export const ADD_NOTIFICATION_EMAIL_TOLIST_SUCCESS = "[NOTIFICATIONEMAIL] ADD SUCCESS";
export const ADD_NOTIFICATION_EMAIL_TOLIST_FAILURE = "[NOTIFICATIONEMAIL] ADD FAILURE";

// Notifications action types
export const GET_NOTIFICATIONS_LIST_REQUEST = "[NOTIFICATIONS] GET LIST REQUEST";
export const GET_NOTIFICATIONS_LIST_SUCCESS = "[NOTIFICATIONS] GET LIST SUCCESS";
export const GET_NOTIFICATIONS_LIST_FAILURE = "[NOTIFICATIONS] GET LIST FAILURE";
export const MARK_NOTIFICATION_READ_REQUEST = "[NOTIFICATION] MARK READ REQUEST";
export const MARK_NOTIFICATION_READ_SUCCESS = "[NOTIFICATION] MARK READ SUCCESS";
export const MARK_NOTIFICATION_READ_FAILURE = "[NOTIFICATION] MARK READ FAILURE";
export const DELETE_NOTIFICATION_REQUEST = "[NOTIFICATION] DELETE REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "[NOTIFICATION] DELETE SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "[NOTIFICATION] DELETE FAILURE";

// Trying Payment action types
export const TRY_PAYMENT_REQUEST = "[TRYPAYMENT] PAYMENT REQUEST";
export const TRY_PAYMENT_SUCCESS = "[TRYPAYMENT] PAYMENT SUCCESS";
export const TRY_PAYMENT_FAILURE = "[TRYPAYMENT] PAYMENT FAILURE";
export const TRY_PAYMENT_CLEARHISTORY = "[TRYPAYMENT] PAYMENT CLEAR";

// User License action types
export const GET_USER_LICENSE_LIST_REQUEST = "[USER_LICENSE] GET LIST REQUEST";
export const GET_USER_LICENSE_LIST_SUCCESS = "[USER_LICENSE] GET LIST SUCCESS";
export const GET_USER_LICENSE_LIST_FAILURE = "[USER_LICENSE] GET LIST FAILURE";
