import * as React from "react";
import { useSelector } from "react-redux";

import { 
  Paper,
  styled,
  Chip,
  Badge,
  Tooltip,
  IconButton,
  Radio,
  FormControlLabel 
} from "@mui/material";

import {
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";


import { PaymentIcon } from "react-svg-credit-card-payment-icons";

const PaperWrapper = styled(Paper)(
  () => `
    width: 345;
    height: 200;
    padding: 18px;
    padding-top: 36px;
`
);


export default function ActionAreaCard(props:any) {
  const { user, paymentloading } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  return (
    <PaperWrapper elevation={24} square={false} sx={{width:345, height:200, display: "flex", flexDirection: "column", justifyContent: "space-between", margin:"8px" }}> 
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>  
        <div className="flex-none">  
          <Badge badgeContent={props?.cardInfo?.verified === "yes" ? <Tooltip title="Verified" arrow><CheckIcon sx={{ fontSize: 10 }} /></Tooltip> : props?.cardInfo?.verified === "checking" ? <Tooltip title="Checking" arrow><p>...</p></Tooltip> : <Tooltip title="No Verified" arrow><CloseIcon  sx={{ fontSize: 10 }} /></Tooltip>} color={props?.cardInfo?.verified === "yes" ? "success" : props?.cardInfo?.verified === "checking" ? "secondary" : "error"}>
            <PaymentIcon type={props?.cardInfo?.cardDetail?.brand} format="logoBorder" width={62} />
          </Badge>

        </div>
        <div className="flex-row items-center">  
          <div className="text-2xl">
            ••••• {props?.cardInfo?.cardDetail?.last4}
          </div>
          <div className="text-sm">Expires:{props?.cardInfo?.cardDetail?.exp_month}/{props?.cardInfo?.cardDetail?.exp_year}</div>
        </div>  
        <div className="flex-none">  
          {
            props?.cardInfo?.verified === "no" ? 
              <Chip label="Verify now" variant="outlined" disabled={paymentloading} onClick={() => props.ClickVerifyCard(props.cardInfo)} /> : 
              props?.cardInfo?.verified === "checking" ?
                <Chip label="Check now" variant="outlined" disabled={paymentloading} onClick={() => props.ClickCheckVerification(props.cardInfo)} /> : 
                <Chip label="Verified" variant="outlined" disabled={true} />
          }
        </div>
      </div>
      <div className="flex justify-between" >  
        <div className="flex-none">
          <FormControlLabel  
            control={  
              <Radio  
                checked={user?.primarypayment === props?.cardInfo?.paymentId}
                disabled={props?.cardInfo?.verified !== "yes" || paymentloading}
                onChange={() => props.setPrimaryPayment(props.cardInfo)}  
                value="a"  
                name="radio-buttons"  
                inputProps={{ "aria-label": "A" }}  
              />  
            }  
            label="Primary" // Replace with your desired label  
          />
        </div>
        <div className="flex-none">
          <IconButton color="error" aria-label="delete this card"  onClick={() => props.ClickDeleteCard(props.cardInfo)} disabled={paymentloading} >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </PaperWrapper>
  );
}