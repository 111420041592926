import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import axios, { AxiosError } from "axios";

import { 
  Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, FormHelperText
} from "@mui/material";


import { orderType } from "../../config/static-data";
import LabelInputWrapper from "../../components/form/LabelnputWrapper";
import SelectInput from "../../components/custom-input/SelectInput";

import { OrderInput, OrderSchema } from "../../lib/validations/order.schema";
import { getOrderDraft, placeOrder, placeOrderDraft, deleteOrderDraft } from "../../redux/actions/order.action";
import { ReduxResponse } from "../../types/store";
import { /*clearFormat,*/ getStateCode } from "../../utils/functions";
import { getCellphonecarrierList } from "../../redux/actions/cellphonecarrier.action";
import { getCourtList } from "../../redux/actions/court.action";
import { getBarList } from "../../redux/actions/bar.action";

import ConfirmDlg from "./ConfirmCreatingOrderPage";
import OrderAccountLinkTable from "../../components/order/OrderAccountLinkTable";
import NotificationEmailItemAddDialog from "../../components/order/NotificationEmailItemAddDialog";

interface NotificationEmailItem {
  email: string;
  partyRepresented: string;
  orderingParty: string;
  barID: string;
  // Add any other properties if needed
}

export default function UpdateOrderPage() {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const location = useLocation();
  const updateRecord = location.state?.updateRecord;

  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  const { isLoading } = useSelector(({ orderReducer }) => orderReducer);
  const [ isLoadingNotificationTable, setIsLoadingNotificationTable ] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [notify, setNotify] = useState<boolean>(true);
  const [submitData, setSubmitData] = useState<any>(null);

  // Destructure form hooks for the input
  const methods = useForm<OrderInput>({ resolver: zodResolver(OrderSchema) });
  const { register, handleSubmit, reset, formState, setValue } = methods;
  const { errors } = formState;

  // Handle confirm dialog
  const handleConfirmDialogOpen = () => setIsConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setIsConfirmDialogOpen(false);
  
  const { list:listOfCourts } = useSelector(({ courtReducer }) => courtReducer);
  const { list:listOfCellphoneCarriers } = useSelector(({ cellphoneCarrierReducer }) => cellphoneCarrierReducer  );
  const { list:listOfBars } = useSelector(({ barsReducer }) => barsReducer);
  const { draft } = useSelector(({ orderReducer }) => orderReducer);
  const [ listOfBarsForCurrentCourt, setListOfBarsForCurrentCourt ] = useState<Array<any>>([]);

  const [notificationEmailItems, setNotificationEmailItems] = useState<Array<any>>([]);

  const [notificationemailItemAddDlgOpen, setNotificationemailItemAddDlgOpen] = useState(false);
  const handleNotificationemailItemAddDlgClose = () => setNotificationemailItemAddDlgOpen(false);
  const [notificationEmailItemUpdateContent, setNotificationEmailItemUpdateContent] = useState<NotificationEmailItem>({ email: "", partyRepresented: "", orderingParty: "", barID: "" });

  const [fieldValueOrderNumber, setFieldValueOrderNumber] = useState("");
  const [fieldValueType, setFieldValueType] = useState("");
  const [fieldValueCauseNumber, setFieldValueCauseNumber] = useState("");
  const [fieldValuePlaintiffList, setFieldValuePlaintiffList] = useState("");
  const [fieldValueDefendantList, setFieldValueDefendantList] = useState("");
  const [fieldValuePhoneNumber, setFieldValuePhoneNumber] = useState("");
  const [fieldValueCustomerInformation, setFieldValueCustomerInformation] = useState("");
  const [fieldValueOrderStart, setFieldValueOrderStart] = useState("");
  const [fieldValueOrderEnd, setFieldValueOrderEnd] = useState("");
  const [fieldValueCourt, setFieldValueCourt] = useState("");
  const [fieldValuePhoneCode, setFieldValuePhoneCode] = useState("");
  const [fieldValueBarNumber, setFieldValueBarNumber] = useState("");

  useEffect(() => {
    dispatch(getCourtList());
    dispatch(getCellphonecarrierList());
    dispatch(getBarList());
    if(updateRecord?.ID) {
      // console.log("update");
    } else {
      dispatch(getOrderDraft());
    }
  }, [dispatch]);

  useEffect(() => {
    setNotify(updateRecord ? updateRecord?.notify : draft ? draft[0].notify : true);
    
    if(draft) {
      setFieldValueOrderNumber(updateRecord ? updateRecord?.orderNumber : draft[0]?.orderNumber);
      setFieldValueType(updateRecord ? updateRecord?.type : draft[0]?.type);
      setFieldValueCauseNumber(updateRecord ? updateRecord?.causeNumber : draft[0]?.causeNumber);
      setFieldValuePlaintiffList(updateRecord ? updateRecord?.plaintiffs : draft[0]?.plaintiffs);
      setFieldValueDefendantList(updateRecord ? updateRecord?.defendants : draft[0]?.defendants);
      setFieldValuePhoneNumber(updateRecord ? updateRecord?.orderphoneNumber : draft[0]?.orderphoneNumber);
      setFieldValueCustomerInformation(updateRecord ? updateRecord?.ordercustomerinfo : draft[0]?.ordercustomerinfo);
      setFieldValueOrderStart(updateRecord ? updateRecord?.orderstartdate : draft[0]?.orderstartdate);
      setFieldValueOrderEnd(updateRecord ? updateRecord?.orderstopdate : draft[0]?.orderstopdate);
      setFieldValueCourt(updateRecord ? updateRecord?.court : draft[0]?.court);
      setFieldValueBarNumber(updateRecord ? updateRecord?.barNumber : draft[0]?.barNumber);
      setFieldValuePhoneCode(updateRecord ? updateRecord?.phoneCode : draft[0]?.phoneCode);
      setNotify(updateRecord ? updateRecord?.notify : draft[0] ? draft[0].notify : true);
    }
    
    if( !updateRecord ) {
      // console.log("Draft0", draft ? draft[0].notificationEmailItems : []);
      setNotificationEmailItems(draft ? draft[0].notificationEmailItems : []);
    } else {
      setIsLoadingNotificationTable(true);

      axios.post(`${process.env.REACT_APP_SERVER_API}/order/notificationtabledetail`, {notificationEmailItems : updateRecord?.notificationEmailItems})
        .then(response => {
          if(response.data.success) {
            setNotificationEmailItems(response.data.data);
          }
          setIsLoadingNotificationTable(false);
        })
        .catch((error: AxiosError) => {
          console.log(error);
          setNotificationEmailItems([]);
          setIsLoadingNotificationTable(false);
          navigate("/");
        });
    }

  }, [updateRecord, draft]);

  useEffect(() => {
    setValue("type", fieldValueType);
    setValue("causeNumber", fieldValueCauseNumber);
    setValue("defendants", fieldValueDefendantList);
    setValue("orderphoneNumber", fieldValuePhoneNumber);
    setValue("ordercustomerinfo", fieldValueCustomerInformation);
    setValue("orderstartdate", fieldValueOrderStart);
    setValue("orderstopdate", fieldValueOrderEnd);
    setValue("plaintiffs", fieldValuePlaintiffList);
    setValue("notificationEmailItems", notificationEmailItems);
    setValue("court", fieldValueCourt);
    setValue("barNumber", fieldValueBarNumber);
    setValue("notify", notify);
    setValue("phoneCode", fieldValuePhoneCode);

    saveDraft();

  }, [setValue, fieldValueType, fieldValueCauseNumber, fieldValueDefendantList, fieldValuePhoneNumber, fieldValueCustomerInformation, fieldValueOrderStart, fieldValueOrderEnd, fieldValuePlaintiffList, notificationEmailItems, notify, fieldValueCourt, fieldValueBarNumber, fieldValuePhoneCode]);

  useEffect(() => {
    const currentSelectedCourt = listOfCourts.find((court:any) => court.ID === fieldValueCourt);
    const filteredListOfBars = listOfBars.filter((bar:any) => bar?.barState?.toLowerCase() === currentSelectedCourt?.code?.toLowerCase());
    setListOfBarsForCurrentCourt(filteredListOfBars);
  }, [fieldValueCourt]);

  useEffect(() => {
    if(listOfBarsForCurrentCourt.find((bar:any) => bar.ID === updateRecord?.barNumber))
      setFieldValueBarNumber(updateRecord?.barNumber);
    else
      setFieldValueBarNumber(listOfBarsForCurrentCourt[0]?.ID);
  }, [listOfBarsForCurrentCourt]);

  const clickApprove = () => {

    // console.log(placeOrder, reset);
    if(updateRecord) {
      dispatch(placeOrder({
        ...submitData
      }))
        .then((response: ReduxResponse) => {
          emptyDraft();
          handleConfirmDialogClose();
  
          if (response.success) {
            toast.success(response.message);
            reset();
            navigate("/");
          } else {
            toast.error(response.message);
          }
        })
        .catch((error: Error) => {
          handleConfirmDialogClose();
          toast.error(error.message);
        });
    } else {
      navigate("/order/payment", {state: {newOrderData: submitData}});
    }
  };

  
  const saveDraft = () => {
    if(updateRecord?.ID) {
      // console.log("update");
    } else {
      // console.log("NotificationEmailItems", notificationEmailItems);
      const draftData = {
        type: fieldValueType,
        causeNumber: fieldValueCauseNumber,
        defendants: fieldValueDefendantList,
        orderphoneNumber: fieldValuePhoneNumber,
        ordercustomerinfo: fieldValueCustomerInformation,
        orderstartdate: fieldValueOrderStart,
        orderstopdate: fieldValueOrderEnd,
        plaintiffs: fieldValuePlaintiffList,
        notificationEmailItems: notificationEmailItems,
        court: fieldValueCourt,
        barNumber: fieldValueBarNumber,
        notify: notify,
        phoneCode: fieldValuePhoneCode,

      };
  
      dispatch(placeOrderDraft({
        ...draftData
      }))
        .then((response: ReduxResponse) => {
          console.log(response);
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  };

  const emptyDraft = () => {
    if(updateRecord?.ID) {
      // console.log("empty");
    } else {
    
      dispatch(deleteOrderDraft({
      }))
        .then((response: ReduxResponse) => {
          console.log(response);
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  };
  
  const clickCancel = () => {
    emptyDraft();
    navigate("/");
    //handleConfirmDialogClose();
  };

  const openAddNotificationEmailItemDlg = () => {
    setNotificationEmailItemUpdateContent({ email: "", partyRepresented: "", orderingParty: "", barID: "" });
    setNotificationemailItemAddDlgOpen(true);
  };

  const openUpdateNotificationEmailItemDlg = (row:any) => {
    setNotificationEmailItemUpdateContent(row);
    setNotificationemailItemAddDlgOpen(true);
  };

  const deleteNotificationEmailItem = (row:any) => {
    setNotificationEmailItems(notificationEmailItems.filter(item => item !== row));
  };

  // Open confirmation dialog when submit
  const onSubmit = (data: OrderInput) => {
    // Perform validation checks here
    const orderID = updateRecord?.ID;

    if (user) {
      if (user.state && user.email) {
        setSubmitData({
          ...data,
          ID: orderID,
          email: user.email,
          state: getStateCode(user.state),
          orderphoneNumber: data.orderphoneNumber, //clearFormat(data.orderphoneNumber),
          notify
        });

        handleConfirmDialogOpen();
      } else {
        toast.error("User is not an ordering party. Please complete your profile.");
      }
    } else {
      toast.error("Please login to the platform.");
    }
  };
  
  return (
    <main className="flex justify-center w-full">
      <form className="container flex flex-col w-full pl-8 pr-24 py-16 fade-up-anim anim-500" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-3xl ml-4 mb-8">{updateRecord ? "UPDATE THE ORDER" : "CREATE A NEW ORDER"}</h1>
        <LabelInputWrapper label="Order Number:" className="w-full md:w-2/5">
          <TextField
            variant="standard"
            className="w-full"
            value={fieldValueOrderNumber}
            disabled
          />
        </LabelInputWrapper>
        <LabelInputWrapper label="Document Type:" className="w-full md:w-2/5">
          <SelectInput
            variant="standard"
            className="w-full"
            handler={register("type")}
            error={errors.type ? true : false}
            helperText={errors.type?.message}
            options={{
              data: orderType,
              labelKey: "label",
              valueKey: "value",
              //defaultValue: updateRecord?.type
              value: fieldValueType
            }}
            onChange={(e) => { setFieldValueType(e.target.value); }}
          />
        </LabelInputWrapper>
        <LabelInputWrapper label="Court of Docket:" className="w-full md:w-3/5">
          <SelectInput
            variant="standard"
            className="w-full"
            handler={register("court")}
            error={errors.court ? true : false}
            helperText={errors.court?.message}
            options={{
              data: listOfCourts,
              labelKey: "code",
              labelAssistantKey: "label",
              valueKey: "ID",
              //valueKey: "code",
              value: fieldValueCourt
            }}
            onChange = {(e) => { setFieldValueCourt(e.target.value); }}
          />
        </LabelInputWrapper>
        <LabelInputWrapper label="Cause Number:" className="w-full md:w-3/5">
          <TextField
            variant="standard"
            className="w-full"
            {...register("causeNumber")}
            error={errors.causeNumber ? true : false}
            helperText={errors.causeNumber?.message}
            value={fieldValueCauseNumber}
            onChange={(e) => {setFieldValueCauseNumber(e.target.value);}}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Plaintiff Data:" className="w-full md:w-3/5">
          <TextField
            variant="filled"
            className="w-full"
            {...register("plaintiffs")}
            error={errors.plaintiffs ? true : false}
            helperText={errors.plaintiffs?.message}
            value={fieldValuePlaintiffList}
            onChange={(e) => {setFieldValuePlaintiffList(e.target.value);}}
            multiline
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Defendant Data:" className="w-full md:w-3/5">
          <TextField
            variant="filled"
            className="w-full"
            {...register("defendants")}
            error={errors.defendants ? true : false}
            helperText={errors.defendants?.message}
            value={fieldValueDefendantList}
            onChange={(e) => {setFieldValueDefendantList(e.target.value);}}
            multiline
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Cell Phone Number:" className="w-full md:w-1/2 mt-6">
          <TextField
            variant="standard"
            className="w-full"
            placeholder="(###)###-####"
            {...register("orderphoneNumber")}
            error={errors.orderphoneNumber ? true : false}
            helperText={errors.orderphoneNumber?.message}
            value={fieldValuePhoneNumber}
            onChange={(e) => {setFieldValuePhoneNumber(e.target.value);}}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Customer Information:" className="w-full md:w-1/2 mt-6">
          <TextField
            variant="standard"
            className="w-full"
            {...register("ordercustomerinfo")}
            error={errors.ordercustomerinfo ? true : false}
            helperText={errors.ordercustomerinfo?.message}
            value={fieldValueCustomerInformation}
            onChange={(e) => {setFieldValueCustomerInformation(e.target.value);}}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Order Start Date:" className="w-full md:w-1/2 mt-6">
          <TextField
            variant="standard"
            className="w-full"
            {...register("orderstartdate")}
            error={errors.orderstartdate ? true : false}
            helperText={errors.orderstartdate?.message}
            value={fieldValueOrderStart}
            onChange={(e) => {setFieldValueOrderStart(e.target.value);}}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Order Stop Date:" className="w-full md:w-1/2 mt-6">
          <TextField
            variant="standard"
            className="w-full"
            {...register("orderstopdate")}
            error={errors.orderstopdate ? true : false}
            helperText={errors.orderstopdate?.message}
            value={fieldValueOrderEnd}
            onChange={(e) => {setFieldValueOrderEnd(e.target.value);}}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Carrier Code:" className="w-full md:w-1/3">
          <SelectInput
            variant="standard"
            className="w-full"
            handler={register("phoneCode")}
            error={errors.phoneCode ? true : false}
            helperText={errors.phoneCode?.message}
            options={{
              data: listOfCellphoneCarriers,
              labelKey: "companyname",
              valueKey: "ID",
              //defaultValue: updateRecord?.phoneCode
              value: fieldValuePhoneCode
            }}
            onChange={(e) => {setFieldValuePhoneCode(e.target.value);}}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Bar Number:" className="w-full md:w-1/3">
          <SelectInput
            variant="standard"
            className="w-full"
            handler={register("barNumber")}
            error={errors.barNumber ? true : false}
            helperText={errors.barNumber?.message}
            options={{
              data: listOfBarsForCurrentCourt,
              labelKey: "barState",
              labelAssistantKey: "barNumber",
              valueKey: "ID",
              //defaultValue: updateRecord?.barNumber
              value: fieldValueBarNumber
            }}
            onChange={(e) => {setFieldValueBarNumber(e.target.value); }}
          />
        </LabelInputWrapper>

        <LabelInputWrapper label="Notify to plaintiffs and defendants?" className="flex-col md:flex-row">
          <FormControl className="w-full" >
            <RadioGroup value={notify} onChange={({ target: { value } }) => { setNotify(value === "true"); }} row>
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </LabelInputWrapper>

        <LabelInputWrapper label="Notification Email List" className="w-full flex-col ">
          <FormControl className="w-full" error={errors.notificationEmailItems ? true : false} >
            <OrderAccountLinkTable 
              isLoading = { isLoadingNotificationTable }
              notificationEmailList={notificationEmailItems} updateFunction={openUpdateNotificationEmailItemDlg} addFunction={openAddNotificationEmailItemDlg} deleteFunction={deleteNotificationEmailItem} 
            />
            <FormHelperText>{errors.notificationEmailItems?.message}</FormHelperText>
          </FormControl>
        </LabelInputWrapper>
        
        <div className="flex">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-48 h-12 mt-12 ml-4 bg-primary text-lg tracking-widest"
          >
            {"submit"}
          </Button>

          <Button
            variant="contained"
            color="error"
            className="w-48 h-12 mt-12 ml-4 bg-red-500 text-lg tracking-widest"
            onClick={() => clickCancel()}
          >
            {"cancel"}
          </Button>
        </div>
      </form>
      <NotificationEmailItemAddDialog 
        open={notificationemailItemAddDlgOpen} 
        onClose={()=>handleNotificationemailItemAddDlgClose()} 
        data={notificationEmailItemUpdateContent} 
        saveFunction={(data)=>{
          const existItemIndex = notificationEmailItems.findIndex(item => item.email === data.email && item.partyRepresented === data.partyRepresented && item.orderingParty === data.orderingParty && item.barID === data.barID );
          if(existItemIndex > -1)
            alert("No duplicated record, please!");
          else
            setNotificationEmailItems([...notificationEmailItems, data]);
        }} 
        updateFunction={(data)=>{
          const tmpEmailItems = notificationEmailItems.map(item => {
            if(item.email === notificationEmailItemUpdateContent.email && 
              item.partyRepresented === notificationEmailItemUpdateContent.partyRepresented &&
              item.orderingParty === notificationEmailItemUpdateContent.orderingParty &&
              item.barID === notificationEmailItemUpdateContent.barID
            ) {
              return data;
            } else {
              return item;
            }
          });
          setNotificationEmailItems(tmpEmailItems);
        }} 
      />
      <ConfirmDlg open={isConfirmDialogOpen} onClose={handleConfirmDialogClose} onApprove={clickApprove} onCancel={clickCancel} isLoading={isLoading} submitData={submitData}  />
    </main>
  );
}
