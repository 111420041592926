import { Dispatch } from "redux";
import * as Actions from "../constants";
import axios, { AxiosError } from "axios";
//import { handleAxiosError } from "../../utils/functions";

// Get bar list action
export const getBarList: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.GET_BAR_LIST_REQUEST });

  return axios.get(`${process.env.REACT_APP_SERVER_API}/bars`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_BAR_LIST_SUCCESS,
          payload: response.data
        });
  
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_BAR_LIST_FAILURE
        });
      }
     
    })
    .catch((error: AxiosError) => {
      console.log(error);
      dispatch({
        type: Actions.GET_BAR_LIST_FAILURE,
        error
      });

      return [];
      //handleAxiosError(error);

    });
};

// Create new bar action
export const placeBar: any = (data: any) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.CREATE_BAR_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/bars`, data)
    .then(response => {
      dispatch({ type: Actions.CREATE_BAR_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.CREATE_BAR_FAILURE,
        error
      });
      return [];
      //handleAxiosError(error);
    });
};
