import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { markNotificationAsRead } from "../redux/actions/notifications.action";

import { Alert, Avatar, Badge, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip,
  Popover, Box, Typography, CircularProgress
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { authConfig, userMenuConfig } from "../config/navbar";
import { NavbarConfigType } from "../types/shared";
import { InputAdornment, TextField } from "@mui/material";
import {
  Search as SearchIcon,
  MarkEmailRead as MarkAsReadIcon,
}from "@mui/icons-material";

import { getNotificationsList } from "../redux/actions/notifications.action";

const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export default function Navbar() {
  const navigate = useNavigate();
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  const { 
    newTotal:numOfNewNotifications,
    list:listOfNotifications,
    isLoading:isLoadingNotifications
  } = useSelector(({ notificationsReducer }) => notificationsReducer);
  const [search, setSearch] = useState("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const ref = useRef<any>(null);

  const openAccountMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeAccountMenu = () => setAnchorEl(null);

  const [isOpenNotificationBox, setIsOpenNotificationBox] = useState(false);

  const dispatch = useDispatch();

  const handleCloseNotificationBox = () => {
    setIsOpenNotificationBox(false);
  };

  const handleOpenNotificationBox = () => {
    setIsOpenNotificationBox(true);
  };

  const handleMarkAsRead = (notificationID:string) => {
    dispatch(markNotificationAsRead(notificationID));
  };

  useEffect(() => {
    dispatch(getNotificationsList());
  }, [user]);
  
  return (
    <main className="flex flex-col w-full">
      <div className="flex justify-center w-full bg-gray-800">
        <div className="container flex justify-between items-center w-full h-20 px-8 text-white">
          {
            user ? (
              <>
                <h1
                  className="font-bold text-xl tracking-widest cursor-pointer"
                  onClick={() => navigate("/")}
                >DWQ</h1>
                <div className="flex justify-between items-center">
                  <Tooltip arrow title="Notifications">
                    <IconButton className="mx-2" ref={ref} onClick={handleOpenNotificationBox}>
                      <Badge badgeContent={isLoadingNotifications ? " " : numOfNewNotifications} color="info">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Popover
                    anchorEl={ref.current}
                    onClose={handleCloseNotificationBox}
                    open={isOpenNotificationBox}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                  >
                    <Box
                      sx={{ p: 2, minWidth: 400, maxWidth: 400 }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">Notifications</Typography>
                      <Typography variant="caption">
                      </Typography>
                    </Box>
                    <Divider />
                    {
                      isLoadingNotifications ? (
                        <Box
                          sx={{ p: 2, minWidth: 400, maxWidth: 400 }}
                          display="flex"
                          justifyContent="center"
                        >
                          <CircularProgress color="success"/>
                        </Box>
                      ) : (
                        listOfNotifications.filter((filterItem:any) => filterItem.isSent !== true).map((notificationItem:any) => (
                          <Box flex="1" sx={{ p: 2, minWidth: 400, maxWidth: 400 }} key={notificationItem.ID}>
                            <Box display="flex" justifyContent="space-between">
                              <Typography sx={{ fontWeight: "bold"}} component="span" variant="h6">
                                {notificationItem.notificationTitle}
                              </Typography>
                              <Typography 
                                variant="caption" 
                                sx={{ textTransform: "none", minWidth: 30, display: { xs: "block" } }} 
                                component="span"
                              >
                                <Tooltip arrow title="Mark as Read">
                                  <IconButton onClick={() => handleMarkAsRead(notificationItem.ID)}>
                                    <MarkAsReadIcon />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </Box>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {notificationItem.notificationContent}
                            </Typography>
                          </Box>
                        ))
                      )
                    }
                    <Box
                      sx={{ p: 2, minWidth: 400, maxWidth: 400 }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5"></Typography>
                      <Typography variant="caption">
                        <span
                          className="font-bold text-sm tracking-widest cursor-pointer"
                          onClick={() => {
                            handleCloseNotificationBox();
                            navigate("/notifications");
                          }}
                        >See all notifications...</span>
                      </Typography>
                    </Box>

                  </Popover>

                  <IconButton
                    onClick={openAccountMenu}
                    size="small"
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>{user.firstName ? user.firstName[0] : "D"}</Avatar>
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={menuOpen}
                    onClose={closeAccountMenu}
                    onClick={closeAccountMenu}
                    PaperProps={menuPaperProps}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {
                      user.firstName ? (
                        <h2 className="p-4 font-bold">{user.firstName} {user.lastName}</h2>
                      ) : (
                        <h2 className="p-4 font-bold">Defendant</h2>
                      )
                    }

                    <Divider className="min-w-[240px]" />

                    {
                      userMenuConfig.map((item: NavbarConfigType) => (
                        <MenuItem key={item.id} onClick={() => navigate(item.path)}>
                          <ListItemIcon>
                            <item.icon fontSize="small" />
                          </ListItemIcon>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                    <Divider />
                    {
                      /*
                      authConfig.map((item: NavbarConfigType) => (
                        <MenuItem key={item.id} onClick={() => navigate(item.path)}>
                          <ListItemIcon>
                            <item.icon fontSize="small" />
                          </ListItemIcon>
                          {item.name}
                        </MenuItem>
                      ))
                      */
                    }
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <h1
                  className="font-bold text-xl tracking-widest cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <img
                    src="logo2.png"
                    className="w-[25%] pt-24 self-start"
                  />
                </h1>
                
                <div className="flex justify-between">
                  {
                    authConfig.map((item: NavbarConfigType) => (
                      <Link
                        key={item.id}
                        to={item.path}
                        className="flex items-center mx-4 transition-all text-gray-300 hover:text-white"
                      >
                        <item.icon />
                        <p className="ml-2">{item.name}</p>
                      </Link>
                    ))
                  }
                  <TextField
                    variant="outlined"
                    className="w-40"
                    placeholder="Search..."
                    value={search}
                    onChange={({ target: { value } }) => setSearch(value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </>
            )
          }
        </div>
      </div>

      {
        user && !user.email_verified && (
          <div className="flex justify-center items-center w-full p-2 fade-up-anim anim-500">
            <Alert severity="warning">Please verify your email address.</Alert>
            <Button
              variant="contained"
              className="h-11 mx-2 bg-primary"
              onClick={() => navigate("/user/verify")}
            >
              Verify
            </Button>
          </div>
        )
      }
    </main>
  );
}
