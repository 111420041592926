import React, { useState } from "react";
// import { Dispatch } from "redux";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsListTable from "../components/notifications/NotificationsListTable";

export default function NotificatiosPage() {
  // const dispatch: Dispatch = useDispatch();
  // console.log(dispatch);
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  const [search, setSearch] = useState("");

  return (
    <div className="flex flex-col items-center w-full">
      <main className="container flex flex-col w-full py-12">
        {
          user && (
            <div className="flex justify-between w-full px-4 pb-6">
              <TextField
                variant="outlined"
                className="w-80"
                placeholder="Search items..."
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          )
        }

        <NotificationsListTable search={search} />
      </main>
    </div>
  );
}
