import { Dispatch } from "redux";
import * as Actions from "../constants";
import axios, { AxiosError } from "axios";

// Get notifications list action
export const getNotificationsList: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.GET_NOTIFICATIONS_LIST_REQUEST });
  return axios.get(`${process.env.REACT_APP_SERVER_API}/notifications`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_NOTIFICATIONS_LIST_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_NOTIFICATIONS_LIST_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.GET_NOTIFICATIONS_LIST_FAILURE,
        error
      });
      
      return [];
      /*
      //handleAxiosError(error);
      */
    });
};

// Set Notification as Read action
export const markNotificationAsRead: any = (notificationID:string) => async (dispatch: Dispatch) => {
  
  dispatch({ type: Actions.MARK_NOTIFICATION_READ_REQUEST });

  return axios.put(`${process.env.REACT_APP_SERVER_API}/notifications/${notificationID}`)
    .then(response => {
      if(response.data.success) {
        dispatch({
          type: Actions.MARK_NOTIFICATION_READ_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.MARK_NOTIFICATION_READ_FAILURE,
          payload: response.data
        });
        return response.data;
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.MARK_NOTIFICATION_READ_FAILURE,
        error
      });
      return [];
      /*
      //handleAxiosError(error);
      */
    });
};

export const deleteNotification: any = (notificationID:string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.DELETE_NOTIFICATION_REQUEST });
  return axios.delete(`${process.env.REACT_APP_SERVER_API}/notifications/${notificationID}`)
    .then(response => {
      if(response.data.success) {
        dispatch({
          type: Actions.DELETE_NOTIFICATION_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.DELETE_NOTIFICATION_FAILURE,
          payload: response.data
        });
        return response.data;
      }
      
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.DELETE_NOTIFICATION_FAILURE,
        error
      });
      return [];
      /*
      //handleAxiosError(error);
      */
    });

};