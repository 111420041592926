import { z } from "zod";

export const OrderSchema = z
  .object({
    type: z.string().min(1, "Required").default("D"),
    court: z.string().min(1, "Required"),
    causeNumber: z.string().min(1, "Required"),
    plaintiffs: z.string().min(1, "Required"),
    defendants: z.string().min(1, "Required"),
    notificationEmailItems:  z.array(z.any()).min(1, "Required"),
    orderphoneNumber: z.string().min(1, "Required").regex(/^\(\d{3}\) ?\d{3}-\d{4}$/, "Must be in the format (###) ###-####"), // Phone number for surveying
    ordercustomerinfo: z.string().min(1, "Required"),               // Customer information for surveying
    orderstartdate: z.string().min(1, "Required"),                  // Start date of surveying period
    orderstopdate: z.string().min(1, "Required"),                   // Stop date of surveying period
    phoneCode: z.string().min(1, "Required"),
    barNumber: z.string().min(1, "Required"),
    //notify: z.boolean().optional()
    notify: z.boolean().refine((value) => value === true || false, {
      message: "Required",
      path: ["notify"]
    })
  });

export type OrderInput = z.infer<typeof OrderSchema>;
