import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import cognitouserReducer from "./cognitouser.reducer";
import orderReducer from "./order.reducer";
import courtReducer from "./court.reducer";
import cellphoneCarrierReducer from "./cellphonecarrier.reducer";
import barsReducer from "./bars.reducer";
import notificationemailReducer from "./notificationemail.reducer";
import notificationsReducer from "./notifications.reducer";
import licenseReducer from "./license.reducer";

const reducers = combineReducers({
  userReducer,
  cognitouserReducer,
  orderReducer,
  courtReducer,
  cellphoneCarrierReducer,
  barsReducer,
  notificationemailReducer,
  notificationsReducer,
  licenseReducer
});

export default reducers;
