import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { allRoutes } from "../config/routes";
import { RouteType } from "../types/shared";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import NotFoundPage from "../pages/NotFoundPage";
import Auth from "./AuthorizedProvider";

export default function AppRoutes() {
  const location = useLocation();
  const currentRouteIndex = allRoutes.findIndex((route: RouteType) => route.path === location.pathname);
  const ReturnRouteTable = (<Routes>
    {
      allRoutes.filter((route:RouteType) => route.authRole === "").map((route: RouteType) => (
        <Route key={route.name} path={route.path} Component={route.component} />
      ))
    }
    <Route element={<Auth allowedRoles={["lawyer"]} />} >
      {
        allRoutes.filter((route:RouteType) => route.authRole === "lawyer").map((route: RouteType) => (
          <Route key={route.name} path={route.path} Component={route.component} />
        ))
      } 
    </Route>
    <Route path="*" Component={NotFoundPage} />
  </Routes>);
  
  return (
    <div className="flex flex-col justify-between w-full min-h-screen">
      {
        currentRouteIndex > -1 ? allRoutes[currentRouteIndex].hasTopFooterMenu ? <Navbar /> : <></> : <></>
      }
      {
        ReturnRouteTable
      }
      {
        currentRouteIndex > -1 ? allRoutes[currentRouteIndex].hasTopFooterMenu ? <Footer /> : <></> : <></>
      }
    </div>
  );

 
  /*

  if (authRoutes.findIndex((route: RouteType) => route.path === location.pathname) > -1) {
    return (
      <Routes>
        {
          authRoutes.map((route: RouteType) => (
            <Route key={route.name} path={route.path} Component={route.component} />
          ))
        }
      </Routes>
    );
  } else if (appRoutes.findIndex((route: RouteType) => route.path === location.pathname) > -1) {
    return (
      <div className="flex flex-col justify-between w-full min-h-screen">
        <Navbar />

        <Routes>
          {
            appRoutes.map((route: RouteType) => (
              <Route key={route.name} path={route.path} Component={route.component} />
            ))
          }
        </Routes>

        <Footer />
      </div>
    );
  } else {
    return (
      <Routes>
        <Route path="*" Component={NotFoundPage} />
      </Routes>
    );
  }
  */
}
