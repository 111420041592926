import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, InputAdornment, Slide, TextField, Zoom } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import BarListTable from "../../components/user/BarListTable";

export default function BarsPage() {
  const navigate = useNavigate();
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  const [search, setSearch] = useState("");

  const ClickNewBar = () => {
    navigate("/user/bars/new");
  };
  
  return (
    <div className="flex flex-col items-center w-full">
      <main className="container flex flex-col w-full pt-28">
        {
          user && (
            <div className="px-8 pb-4">
              <div className="flex justify-between w-full">
                <TextField
                  variant="outlined"
                  className="w-80"
                  placeholder="Search items..."
                  value={search}
                  onChange={({ target: { value } }) => setSearch(value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  variant="contained"
                  className="px-4 py-3 bg-primary font-bold"
                  onClick={ClickNewBar}
                >
                  <AddIcon />
                  <p className="ml-2">new bar</p>
                </Button>
              </div>
              <BarListTable search={search} />
            </div>
          )
        }
        {
          user === null && (
            <>
              <div className="flex w-full px-4 relative">
                <Slide
                  direction="right"
                  in={true}
                  timeout={1000}
                  mountOnEnter
                  unmountOnExit
                >
                  <img
                    className="h-auto w-[50%] left-0 bottom-0"
                    src="assets/images/banners/lawyer.png"
                    alt="Lawyer Image"
                    loading="lazy"
                  />
                </Slide>
                <div className="flex flex-col gap-8 relative">
                  <Slide
                    direction="left"
                    in={true}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <p className="text-xl md:text-4xl lg:text-6xl -ml-12 lg:-ml-72">Protecting your rights</p>
                      <p className="text-xl mt-4 md:text-4xl lg:text-6xl -ml-6 lg:-ml-36">Securing your future</p>
                    </div>
                  </Slide>
                  <Zoom
                    in={true}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                    style={{ transitionDelay: "500ms" }}
                  >
                    <div className="flex-grow px-8">
                      <p className="hidden md:block text-md lg:text-2xl my-2 lg:my-8 relative z-[10]">
                        At Leganrend, protect your rights and secure your future. Expert legal guidance and representation by our dedicated attorneys. Favorable outcomes through our experience and dedication. Contact us today for a consultation.
                      </p>
                      <Button
                        variant="contained"
                        className="hidden md:block bg-primary font-bold"
                        size="large"
                      >
                        <p>Contact Us</p>
                      </Button>
                      <img
                        className="w-[60%] md:w-[40%] xl:w-[30%] h-auto right-8 xl:right-24 bottom-8 xl:bottom-24 absolute"
                        src="assets/images/banners/hammer.png"
                        alt="Hammer and Book Image"
                        loading="lazy"
                      />
                    </div>
                  </Zoom>
                </div>
              </div>
            </>
          )
        }


      </main>
    </div>
  );
}
