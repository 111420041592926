import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <main className="flex flex-col w-full">
      <div className="flex justify-center w-full bg-gray-800">
        <div className="container flex flex-col md:flex-row justify-between w-full p-8 text-sm md:text-base">
          <div className="flex flex-col justify-between w-full py-6 md:py-0">
            <div className="flex flex-col">
              <Link to={"/"} className="font-bold text-2xl mb-4">DWQ Legal LLC</Link>

              <Link to={"/privacy-policy"} className="text-gray-300 hover:text-white py-1">Privacy & Policy</Link>
              <Link to={"/terms-and-conditions"} className="text-gray-300 hover:text-white py-1">Terms & Conditions</Link>
            </div>

            <div>
              <p className="py-1">info@dwq.legal</p>
              <p className="py-1">+1 (234) 567-9801</p>
              <p className="py-1">Santa Clara, California, United States</p>
            </div>
          </div>

        </div>
      </div>

      <div className="flex justify-center items-center w-full p-6 bg-gray-900">
        DWQ Legal, LLC © All rights reserved 2024
      </div>
    </main>

  );
}
