import * as React from "react";
import {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { 
  Button, Dialog, DialogContent, DialogTitle,
  Checkbox
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ButtonLoader from "../../components/shared/ButtonLoader";
import LabelInputWrapper from "../../components/form/LabelnputWrapper";

import { orderLabelByType } from "../../config/static-data";
import { getFieldValueFromList } from "../../utils/functions";


export default function ConfirmCreatingOrderPage(props:any) {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const {submitData} = props;

  useEffect(()=>{
    setEnableSubmit(false);
  }, [props.open]);

  const { list:listOfCellphoneCarriers } = useSelector(({ cellphoneCarrierReducer }) => cellphoneCarrierReducer  );
  const { list:listOfCourts } = useSelector(({ courtReducer }) => courtReducer);
  // const { list:listOfBars } = useSelector(({ barsReducer }) => barsReducer);
  
  if(submitData) {
    // console.log(listOfBars);
    // console.log(submitData);

    return(
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle className="text-center">
          <InfoIcon
            sx={{
              fontSize: 32,
              color: "orange"
            }}
          />
        </DialogTitle>

        <DialogContent className="flex flex-col items-center">
          <p className="mx-12 my-4 text-center text-2xl">Do you agree with this order?</p>
          <LabelInputWrapper label="Document Type:" className="w-full md:w-3/5">
            {orderLabelByType(props.submitData["type"])}
          </LabelInputWrapper>
          <LabelInputWrapper label="Court of Docket:" className="w-full md:w-3/5">
            {getFieldValueFromList(listOfCourts, "code", props.submitData["court"])}
          </LabelInputWrapper>
          <LabelInputWrapper label="Cause Number:" className="w-full md:w-3/5">
            {props.submitData["causeNumber"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Plaintiff List:" className="w-full md:w-3/5">
            {props.submitData["plaintiffs"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Defendant List:" className="w-full md:w-3/5">
            {props.submitData["defendants"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Notification Email List:" className="w-full md:w-3/5">
            {props.submitData["notificationEmailItems"].map((item:any)=>item.email).join("\n")}
          </LabelInputWrapper>
          <LabelInputWrapper label="Cell Phone Number:" className="w-full md:w-3/5">
            {props.submitData["orderphoneNumber"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Customer Information:" className="w-full md:w-3/5">
            {props.submitData["ordercustomerinfo"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Order Start Date:" className="w-full md:w-3/5">
            {props.submitData["orderstartdate"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Order Stop Date:" className="w-full md:w-3/5">
            {props.submitData["orderstopdate"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Carrier Code:" className="w-full md:w-3/5">
            {getFieldValueFromList(listOfCellphoneCarriers, "companyname", props.submitData["phoneCode"])}
          </LabelInputWrapper>
          <LabelInputWrapper label="Notify?:" className="w-full md:w-3/5">
            {props.submitData["notify"] ? "YES" : "NO"}
          </LabelInputWrapper>  
          <LabelInputWrapper label="Agree to place this order:" className="w-full md:w-3/5">
            <Checkbox
              value={enableSubmit}
              checked={enableSubmit }
              onChange={()=>setEnableSubmit(!enableSubmit)}
              inputProps={{ "aria-label": "Checkbox A" }}
            />
          </LabelInputWrapper>  
          <div className="flex justify-center my-8">
            <Button
              variant="contained"
              color="primary"
              className="w-36 h-12 mx-4 bg-primary text-lg"
              onClick={props.onApprove}
              disabled={!enableSubmit}
            >
              {props.isLoading ? <ButtonLoader /> : "Approve"}
            </Button>

            <Button
              variant="contained"
              color="error"
              className="w-36 h-12 mx-4 bg-red-500 text-lg"
              onClick={props.onCancel}
            >
              {"Cancel"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  } else
    return(
      <>
      </>
    );
}