import * as Actions from "../../redux/constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  isOnAuthorizing: false,
  isVerifying: false,
  isConfirmEmail: false,
  user: null,
  error: null,
  paymentlist: [],
  paymentloading: false
};
 
const cognitouserReducer = (state = initialState, action: DispatchAction) => {
  switch (action.type) {
  // Register reducer
  case Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_REQUEST: {
    return {
      ...state,
      isVerifying: true,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_SUCCESS: {
    return {
      ...state,
      user: action.payload,
      isVerifying: false,
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_FAILURE: {
    return {
      ...state,
      isVerifying: false,
      isLoading: true,
      error: action.error
    };
  }
  // Email verification reducer
  case Actions.COGNITOUSER_VERIFY_EMAIL_REQUEST: {
    return {
      ...state,
      isVerifying: true,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_VERIFY_EMAIL_SUCCESS: {

    return {
      ...state,
      isVerifying: false,
      isLoading: false,
      user: {
        ...(state.user || {}),
        email_verified: true
      }
    };
  }
  case Actions.COGNITOUSER_VERIFY_EMAIL_FAILURE: {
    return {
      ...state,
      isVerifying: false,
      isLoading: false,
      error: action.error
    };
  }
  case Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_SUCCESS: {
    return {
      ...state,
      user: {
        ...(state.user || {}),
        UserStatus: action.payload.result
      },
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }
  case Actions.COGNITOUSER_GET_AGREEMENT_STATUS_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_GET_AGREEMENT_STATUS_SUCCESS: {
    return {
      ...state,
      user: {
        ...(state.user || {}),
        AgreementStatus: action.payload.result
      },
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_GET_AGREEMENT_STATUS_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }
  case Actions.COGNITOUSER_REGISTER_REQUEST: {
    return {
      ...state,
      isLoading: true,
    };
  }
  case Actions.COGNITOUSER_REGISTER_SUCCESS: {
    return { 
      ...state,
      isLoading: false,
      user: {
        ...(state.user || {}),
        email_verified: true
      }
    };
  }
  case Actions.COGNITOUSER_REGISTER_FAILURE: {
    return { 
      ...state,
      isLoading: false,
    };
  }
  case Actions.COGNITOUSER_UPDATEINFO_REQUEST: {
    return {
      ...state,
      isLoading: true,
    };
  }
  case Actions.COGNITOUSER_UPDATEINFO_SUCCESS: {
    return { 
      ...state,
      isLoading: false,
      user: {
        ...(state.user || {}),
        ...action.payload.data
      }
    };
  }
  case Actions.COGNITOUSER_UPDATEINFO_FAILURE: {
    return { 
      ...state,
      isLoading: false,
    };
  }

  // Login reducer
  case Actions.COGNITOUSER_LOGIN_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_LOGIN_SUCCESS: {
    return {
      ...state,
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_LOGIN_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }
  // Login with token reducer
  case Actions.COGNITOUSER_LOGIN_WITH_TOKEN_REQUEST: {
    return {
      ...state,
      isOnAuthorizing: true
    };
  }
  case Actions.COGNITOUSER_LOGIN_WITH_TOKEN_SUCCESS: {
    return {
      ...state,
      isOnAuthorizing: false,
      user: action.payload
    };
  }
  case Actions.COGNITOUSER_LOGIN_WITH_TOKEN_FAILURE: {
    return {
      ...state,
      isOnAuthorizing: false,
      error: action.error
    };
  }

  case Actions.COGNITOUSER_LOGOUT: {
    return initialState;
  }

  case Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_REQUEST: {
    return {
      ...state,
      paymentloading: true,
    };
  }
  case Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_SUCCESS: {
    return {
      ...state,
      paymentloading: false,
    };
  }
  case Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_FAILURE: {
    return {
      ...state,
      paymentloading: false,
    };
  }

  case Actions.COGNITOUSER_ADDPAYMENT_REQUEST: {
    return {
      ...state,
      paymentloading: true,
    };
  }
  case Actions.COGNITOUSER_ADDPAYMENT_SUCCESS: {
    return {
      ...state,
      paymentloading: false,
      paymentlist: [...state.paymentlist, action.payload.data]
    };
  }
  case Actions.COGNITOUSER_ADDPAYMENT_FAILURE: {
    return {
      ...state,
      paymentloading: false,
    };
  }

  case Actions.COGNITOUSER_GETPAYMENTLIST_REQUEST: {
    return {
      ...state,
      paymentloading: true,
    };
  }
  case Actions.COGNITOUSER_GETPAYMENTLIST_SUCCESS: {
    return {
      ...state,
      paymentlist: action.payload.data,
      paymentloading: false,
    };
  }
  case Actions.COGNITOUSER_GETPAYMENTLIST_FAILURE: {
    return {
      ...state,
      paymentloading: false,
    };
  }
  case Actions.COGNITOUSER_PAYMENTVERIFICATION_REQUEST: {
    return {
      ...state,
      paymentloading: true
    };
  }
  case Actions.COGNITOUSER_PAYMENTVERIFICATION_SUCCESS: {
    return {
      ...state,
      paymentlist: state.paymentlist.map((paymentItem:any) => {
        if(paymentItem.paymentId === action.payload.data.paymentId)
          return action.payload.data;
        else
          return paymentItem;
      }),
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_PAYMENTVERIFICATION_FAILURE: {
    return {
      ...state,
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_DELETEPAYMENT_REQUEST: {
    return {
      ...state,
      paymentloading: true
    };
  }
  case Actions.COGNITOUSER_DELETEPAYMENT_SUCCESS: {
    return {
      ...state,
      paymentlist: state.paymentlist.filter((paymentItem:any) => paymentItem.ID !== action.payload.data.ID),
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_DELETEPAYMENT_FAILURE: {
    return {
      ...state,
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_SETPRIMARYPAYMENT_REQUEST: {
    return {
      ...state,
      paymentloading: true
    };
  }
  case Actions.COGNITOUSER_SETPRIMARYPAYMENT_SUCCESS: {
    // console.log("aaa", action.payload.data);
    return {
      ...state,
      user: {
        ...(state.user || {}),
        primarypayment: action.payload.data.primarypayment
      },
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_SETPRIMARYPAYMENT_FAILURE: {
    return {
      ...state,
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_REQUEST: {
    return {
      ...state,
      paymentloading: true
    };
  }
  case Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_SUCCESS: {
    return {
      ...state,
      paymentlist: state.paymentlist.map((paymentItem:any) => {
        if(paymentItem.paymentId === action.payload.data.paymentId)
          return action.payload.data;
        else
          return paymentItem;
      }),
      paymentloading: false
    };
  }
  case Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_FAILURE: {
    return {
      ...state,
      paymentlist: state.paymentlist.map((paymentItem:any) => {
        if(paymentItem.paymentId === action.payload?.data?.paymentId)
          return action.payload.data;
        else
          return paymentItem;
      }),
      paymentloading: false
    };
  }
  
  default: {
    if(state)
      return state;
    return initialState;
  }
  }
};

export default cognitouserReducer;
