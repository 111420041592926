import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch } from "redux";
import { 
  Avatar, Button, Divider, Paper,
  TextField 
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ProfileBannerImage from "../../components/form/ProfileBannerImage";
import ProfileRecord from "../../components/shared/ProfileRecord";
import { UpdateUserInput, UpdateUserSchema } from "../../lib/validations/user.schema";
import { ReduxResponse } from "../../types/store";
import { updateUser } from "../../redux/actions/cognitouser.action";
import ButtonLoader from "../../components/shared/ButtonLoader";

export default function UpdateProfilePage() {
  const navigate = useNavigate();
  const { user, isLoading } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  
  const [updateFirstName, setUpdateFirstName] = useState("");
  const [updateMiddleName, setUpdateMiddleName] = useState("");
  const [updateLastName, setUpdateLastName ] = useState("");
  const [updateFirmName, setUpdateFirmName ] = useState("");
  const [updateFirmWebsite, setUpdateFirmWebsite] = useState("");
  const [updateAddress1, setUpdateAddress1] = useState("");
  const [updateAddress2, setUpdateAddress2] = useState("");
  const [updateCity, setUpdateCity] = useState("");
  const [updateState, setUpdateState] = useState("");
  const [updateZip, setUpdateZip] = useState("");

  // Destructure form hooks for the input
  const methods = useForm<UpdateUserInput>({ resolver: zodResolver(UpdateUserSchema) });
  const { register, handleSubmit, reset, formState, setValue } = methods;
  const { errors } = formState;

  const dispatch: Dispatch = useDispatch();

  // console.log(reset);
  
  useEffect(() => {
    setUpdateFirstName(user?.firstName);
    setUpdateMiddleName(user?.middleName);
    setUpdateLastName(user?.lastName);
    setUpdateFirmName(user?.firmName);
    setUpdateFirmWebsite(user?.firmDomain);
    setUpdateAddress1(user?.address1);
    setUpdateAddress2(user?.address2);
    setUpdateCity(user?.city);
    setUpdateState(user?.state);
    setUpdateZip(user?.zip);
  }, [user]);
  
  useEffect(() => {
    setValue("firstName", updateFirstName);
    setValue("middleName", updateMiddleName);
    setValue("lastName", updateLastName);
  }, [updateFirstName, updateMiddleName, updateLastName]);
  useEffect(() => {
    setValue("firmName", updateFirmName);
    setValue("firmDomain", updateFirmWebsite);
  }, [updateFirmName, updateFirmWebsite]);  
  useEffect(() => {
    setValue("address1", updateAddress1);
    setValue("address2", updateAddress2);
  }, [updateAddress1, updateAddress2]);  
  useEffect(() => {
    setValue("city", updateCity);
    setValue("state", updateState);
  }, [updateCity, updateState]);  
  useEffect(() => {
    setValue("zip", updateZip);
  }, [updateZip]);  

  // Handle update form submit
  function onSubmit(data: UpdateUserInput) {
    const updateData: UpdateUserInput = {
      ...data
    };

    dispatch(updateUser(updateData))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
          reset();
          navigate("/");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error.message));
  }

  // Handle Cancel
  function clickCancel() {
    navigate("/user/profile");
  }

  return (user && user.firstName) ? (
    <main className="flex flex-col items-center w-full">
      <ProfileBannerImage />
      <div className="container flex flex-col items-center justify-center mx-4 pb-16">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center fade-right-anim anim-500"
        >
          <Paper className="flex flex-col max-w-[600px] p-8 -mt-20 z-10 fade-up-anim anim-500">
            <div className="flex flex-col items-center">
              <h1 className="m-4 text-3xl">Update Profile</h1>
              <Avatar className="w-32 h-32 border-2 border-gray-600 bg-gray-500 text-7xl text-gray-200">{user.firstName[0]}</Avatar>
            </div>
            <div className="flex flex-col items-center">
              <ProfileRecord icon={EmailIcon} content={user.email} />
            </div>
            <div className="my-6">
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="First Name"
                  variant="outlined"
                  className="w-full md:w-44 my-2"
                  {...register("firstName")}
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName?.message}
                  value={updateFirstName}
                  onChange={(e) => setUpdateFirstName(e.target.value) }
                />
                <TextField
                  label="Middle Name"
                  variant="outlined"
                  className="w-full md:w-40 md:mx-4 my-2"
                  {...register("middleName")}
                  error={errors.middleName ? true : false}
                  helperText={errors.middleName?.message}
                  value={updateMiddleName}
                  onChange={(e) => setUpdateMiddleName(e.target.value) }
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  className="w-full md:w-44 my-2"
                  {...register("lastName")}
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName?.message}
                  value={updateLastName}
                  onChange={(e) => setUpdateLastName(e.target.value) }
                />
              </div>
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="Firm Name"
                  variant="outlined"
                  className="w-full md:w-44 my-2"
                  {...register("firmName")}
                  error={errors.firmName ? true : false}
                  helperText={errors.firmName?.message}
                  value={updateFirmName}
                  onChange={(e) => setUpdateFirmName(e.target.value) }
                />
                <TextField
                  label="Firm Website"
                  variant="outlined"
                  className="w-full md:w-40 md:ml-4 my-2 flex-grow"
                  {...register("firmDomain")}
                  error={errors.firmDomain ? true : false}
                  helperText={errors.firmDomain?.message}
                  value={updateFirmWebsite}
                  onChange={(e) => setUpdateFirmWebsite(e.target.value) }
                />
              </div>
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="Address1"
                  variant="outlined"
                  className="w-full my-2"
                  {...register("address1")}
                  error={errors.address1 ? true : false}
                  helperText={errors.address1?.message}
                  value={updateAddress1}
                  onChange={(e) => setUpdateAddress1(e.target.value) }
                />
              </div>
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="Address2"
                  variant="outlined"
                  className="w-full my-2"
                  {...register("address2")}
                  error={errors.address2 ? true : false}
                  helperText={errors.address2?.message}
                  value={updateAddress2}
                  onChange={(e) => setUpdateAddress2(e.target.value) }
                />
              </div>
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="City"
                  variant="outlined"
                  className="w-full md:w-44 my-2"
                  {...register("city")}
                  error={errors.city ? true : false}
                  helperText={errors.city?.message}
                  value={updateCity}
                  onChange={(e) => setUpdateCity(e.target.value) }
                />
                <TextField
                  label="State"
                  variant="outlined"
                  className="w-full md:w-44 my-2 mx-2"
                  {...register("state")}
                  error={errors.state ? true : false}
                  helperText={errors.state?.message}
                  value={updateState}
                  onChange={(e) => setUpdateState(e.target.value) }
                />
                <TextField
                  label="Zip"
                  variant="outlined"
                  className="w-full md:w-44 my-2"
                  {...register("zip")}
                  error={errors.zip ? true : false}
                  helperText={errors.zip?.message}
                  value={updateZip}
                  onChange={(e) => setUpdateZip(e.target.value) }
                />
              </div>
            </div>
            <Divider flexItem />

            <div className="flex justify-center">
              <Button
                type="submit"
                variant="contained"
                className="w-64 h-12 my-4 bg-primary font-bold text-lg tracking-widest"
                disabled={
                  updateFirstName === user?.firstName && 
                  updateMiddleName === user?.middleName &&
                  updateLastName === user?.lastName &&
                  updateFirmName === user?.firmName &&
                  updateFirmWebsite === user?.firmDomain &&
                  updateAddress1 === user?.address1 &&
                  updateAddress2 === user?.address2 &&
                  updateCity === user?.city &&
                  updateState === user?.state &&
                  updateZip === user?.zip
                }
              >
                {isLoading ? <ButtonLoader /> : "Save"}
              </Button>
              <Button
                variant="contained"
                color="error"
                className="w-36 h-12 ml-4 my-4 bg-red-500 text-lg tracking-widest"
                onClick={() => clickCancel()}
              >
                {"cancel"}
              </Button>
            </div>
          </Paper>
        </form>
      </div>
      
    </main>
  ) : <></>;
}