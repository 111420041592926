import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";

import * as Actions from "../constants";
import { 
  CheckIfRegisteredUserInput, 
  RegisterUserInput,
  UpdateUserInput,
  LoginUserInput,
} from "../../lib/validations/user.schema";

import { VerifyEmailInput } from "../../types/cognitouser";
import { handleAxiosError } from "../../utils/functions";

export const checkIfVerifiedEmail:any = (data:CheckIfRegisteredUserInput) => async (dispatch: Dispatch) => {
  dispatch({ 
    type: Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_REQUEST
  });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/checkemailverifiedstatus`, data)
    .then(response => {
      dispatch({ 
        type: Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_SUCCESS,
        payload: {
          ...data,
          UserStatus: response.data.result
        }
      });
      return response.data;
    })
    .catch((error:AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Verify user email action
export const verifyEmail: any = (data: VerifyEmailInput) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_VERIFY_EMAIL_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/verifyemail`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_VERIFY_EMAIL_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_VERIFY_EMAIL_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Resend a verification code if didn't receive
export const resendCode: any = (email: string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.RESEND_EMAIL_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/resendverificationcode`, { email })
    .then(response => {
      dispatch({ type: Actions.RESEND_EMAIL_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({ type: Actions.RESEND_EMAIL_FAILURE });
      handleAxiosError(error);
    });
};

// Verify user email action
export const checkConfirmationStatus: any = (data: VerifyEmailInput) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/checkconfirmationstatus`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_SUCCESS, payload:response.data });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// check user agreement status
export const checkAgreementStatus: any = (data: VerifyEmailInput) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_GET_AGREEMENT_STATUS_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/check-agreement-status`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_GET_AGREEMENT_STATUS_SUCCESS, payload:response.data });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_GET_AGREEMENT_STATUS_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Register action
export const registerUser: any = (data: RegisterUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_REGISTER_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/register`, data)
    .then(response => {
      // console.log("register response:", response);
      if (response.data.success) {
        localStorage.setItem("access-token", response.data.token);
        // console.log("register", response.data);
        //dispatch(loginWithToken(false));
        dispatch({ type: Actions.COGNITOUSER_REGISTER_SUCCESS });
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_REGISTER_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_REGISTER_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Update action
export const updateUser: any = (data: UpdateUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_UPDATEINFO_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/attributes`, data)
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_UPDATEINFO_SUCCESS, payload:response.data });
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_UPDATEINFO_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_UPDATEINFO_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Login action
export const loginUser: any = (data: LoginUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_LOGIN_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/login`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_LOGIN_SUCCESS });
      if (response.data.success) {
        localStorage.setItem("access-token", response.data.token);
        dispatch(loginWithToken(true));
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_LOGIN_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Login with JWT token action
export const loginWithToken: any = (refresh: boolean = true) => async (dispatch: Dispatch) => {
  if (refresh) {
    dispatch({ type: Actions.COGNITOUSER_LOGIN_WITH_TOKEN_REQUEST });
  }

  const accessToken: string | null = localStorage.getItem("access-token");
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios.get(`${process.env.REACT_APP_SERVER_API}/cognitouser/access-token`)
    .then(async response => {
      dispatch({
        type: Actions.COGNITOUSER_LOGIN_WITH_TOKEN_SUCCESS,
        payload: response.data
      });
      localStorage.setItem("currentuser", response.data);
      dispatch(GetPaymentList());
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: Actions.COGNITOUSER_LOGIN_WITH_TOKEN_FAILURE,
        error
      });

      localStorage.removeItem("access-token");
      localStorage.removeItem("currentuser");
    });
};

// Logout user action
export const logoutUser: any = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("currentuser");
  axios.defaults.headers.common.Authorization = "Bearer ";
  return { type: Actions.COGNITOUSER_LOGOUT };
};

//--------------- Payment Actions --------------------
// Get Customer ID
export const GetCustomerID: any = (cardData: any) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/payments/customerid`, cardData)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_SUCCESS,
          payload: response.data
        });
  
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_FAILURE
        });
      }
     
    })
    .catch((error: AxiosError) => {
      console.log(error);
      dispatch({
        type: Actions.COGNITOUSER_GETPAYMENTCUSTOMERID_FAILURE,
        error
      });

      return [];
      handleAxiosError(error);

    });
}; 

// Add payment action
export const AddPayment: any = (paymentId: string, cardDetail: any) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_ADDPAYMENT_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/payments`, {paymentId, cardDetail})
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_ADDPAYMENT_SUCCESS, payload:response.data });
      } else {
        dispatch({
          type: Actions.COGNITOUSER_ADDPAYMENT_FAILURE
        });
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_ADDPAYMENT_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Get payment list action
export const GetPaymentList: any = () => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_GETPAYMENTLIST_REQUEST });

  return axios.get(`${process.env.REACT_APP_SERVER_API}/payments`)
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_GETPAYMENTLIST_SUCCESS, payload:response.data });
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_GETPAYMENTLIST_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      // console.log("GetPaymentList-06");
      dispatch({
        type: Actions.COGNITOUSER_GETPAYMENTLIST_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Try payment verification
export const TryPaymentVerification: any = (paymentInfoID: string) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_PAYMENTVERIFICATION_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/payments/verification`, {ID:paymentInfoID})
    .then(response => {
      if (response.data.success) {
        // console.log("response", response);
        dispatch({ type: Actions.COGNITOUSER_PAYMENTVERIFICATION_SUCCESS, payload:response.data });
      } else {
        dispatch({
          type: Actions.COGNITOUSER_PAYMENTVERIFICATION_FAILURE
        });
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_PAYMENTVERIFICATION_FAILURE,
        error
      });

      handleAxiosError(error);
      return error;
    });
};

// Try payment delete
export const TryPaymentDelete:any = (paymentInfoID: string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_DELETEPAYMENT_REQUEST});
  return axios.delete(`${process.env.REACT_APP_SERVER_API}/payments/${paymentInfoID}` )
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_DELETEPAYMENT_SUCCESS, payload:response.data });
      } else {
        dispatch({
          type: Actions.COGNITOUSER_DELETEPAYMENT_FAILURE
        });
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_DELETEPAYMENT_FAILURE,
        error
      });

      handleAxiosError(error);
      return error;
    });
};

// Try Setting Primary payment
export const TrySettingPrimaryPayment:any = (paymentId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_SETPRIMARYPAYMENT_REQUEST});
  return axios.put(`${process.env.REACT_APP_SERVER_API}/payments/setprimary/${paymentId}` )
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_SETPRIMARYPAYMENT_SUCCESS, payload:response.data });
      } else {
        dispatch({
          type: Actions.COGNITOUSER_SETPRIMARYPAYMENT_FAILURE
        });
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_SETPRIMARYPAYMENT_FAILURE,
        error
      });
      handleAxiosError(error);
      return error;
    });
};

export const CheckVerificationValue: any = (paymentInfoID: string, value: number) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/payments/checkverificationvalue`, {ID:paymentInfoID, value})
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_SUCCESS, payload:response.data });
      } else {
        dispatch({
          type: Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_FAILURE, payload:response.data });
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_CHECKPAYMENTVERIFICATIONVALUE_FAILURE,
        error
      });

      handleAxiosError(error);
      return error;
    });
};