import React, {useState, ChangeEvent, FocusEvent} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch } from "redux";
import { 
  Avatar, Button, Divider, Paper,
  TextField 
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ProfileBannerImage from "../../components/form/ProfileBannerImage";
import ProfileRecord from "../../components/shared/ProfileRecord";
import { UpdateCardInput, UpdateCardSchema } from "../../lib/validations/user.schema";
import ButtonLoader from "../../components/shared/ButtonLoader";
import { AddPayment } from "../../redux/actions/cognitouser.action";
import { ReduxResponse } from "../../types/store";

import { toast } from "react-toastify";
import axios from "axios";
// react credit cards styles
import Cards, {Focused} from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
//import Payment from "payment";
import qs from "qs";

interface CardState {
  number: string;
  name: string;
  expiry: string;
  cvc: string;
  zip: string;
  focus: Focused | "";
}


const CreatePaymentMethod = async (data:any) => {
  try {
    // Convert the data object to URL-encoded format
    const urlEncodedData = qs.stringify(data);

    // Log the Stripe key to ensure it is being loaded correctly (remove in production)
    const response = await axios.post(
      "https://api.stripe.com/v1/payment_methods",
      urlEncodedData,
      {
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response;
  } catch (error) {
    return null;
  }
};

const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
};
const formatCreditCardNumber = (value: string) => {
  if (!value) {
    return value;
  }

  //const issuer = Payment.fns.cardType(value);
  
  const clearValue = clearNumber(value);
  //let nextValue;
  /*
  switch (issuer) {
  case "amex":
    nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      10
    )} ${clearValue.slice(10, 15)}`;
    break;
  case "dinersclub":
    nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      10
    )} ${clearValue.slice(10, 14)}`;
    break;
  default:
    nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      8
    )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
    break;
  }
  return nextValue.trim();
  */
  const maxLength = 19;
  return clearValue.slice(0, maxLength);
};
const formatCVC = (value: string) => {
  const clearValue = clearNumber(value);
  const maxLength = 4;
  return clearValue.slice(0, maxLength);
};
const formatExpirationDate = (value: string) => {

  const clearValue = clearNumber(value);
  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }
  return clearValue;
};

export default function UpdateProfileCardPage() {
  const navigate = useNavigate();
  const { user, isLoading, paymentloading } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  // Destructure form hooks for the input
  const methods = useForm<UpdateCardInput>({ resolver: zodResolver(UpdateCardSchema) });
  const { register, handleSubmit, reset, formState, /* setValue */ } = methods;
  const { errors } = formState;

  const dispatch: Dispatch = useDispatch();

  const [cardState, setCardState] = useState<CardState>({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    zip: "",
    focus: "",
  });

  const handleCardDataInputChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    let newValue = value;
    if(name === "number" ) {
      newValue = formatCreditCardNumber(value);
    } else if(name === "expiry") {
      newValue = formatExpirationDate(value);
    } else if(name === "cvc") {
      newValue = formatCVC(value);
    }
    setCardState((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleCardDataInputFocus = (evt: FocusEvent<HTMLInputElement>) => {
    setCardState((prev) => ({ ...prev, focus: evt.target.name as Focused }));
  };

  

  // Handle update form submit
  async function onSubmit(data: UpdateCardInput) {
    // console.log(data);
    
    // Create a payment method and get the payment ID
    const expiration_date = data.expiry.split("/");
    const cardData = {
      type: "card",
      "card[number]": data.number,
      "card[exp_month]": expiration_date[0],
      "card[exp_year]": expiration_date[1],
      "card[cvc]": data.cvc,
      //"card[address_zip]":data.zip,
      "billing_details[address][postal_code]": data.zip, // Include ZIP code here
    };
    const paymentData = await CreatePaymentMethod(cardData);
    if(paymentData?.status !== 200) {
      toast.error("Failed in creating the payment method! Please try again later!");
      return;
    }

    // Link this payment info with the customer ID
    dispatch(AddPayment(paymentData?.data.id, paymentData?.data.card))
      .then((response: ReduxResponse) => {
        // console.log("AddPayment Response", response);
        if (response.success) {
          toast.success(response.message);
          reset();
          navigate("/user/paymentcard");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error.message));

    return;
  }

  // Handle Cancel
  function clickCancel() {
    navigate("/user/paymentcard");
  }

  return (user && user.firstName) ? (
    <main className="flex flex-col items-center w-full">
      <ProfileBannerImage />
      
      <div className="container flex flex-col items-center justify-center mx-4 pb-16 grid ">
        <Paper className="flex flex-col max-w-[600px] p-8 -mt-20 z-10 fade-up-anim anim-500">
          <div className="flex flex-col items-center">
            <h1 className="m-4 text-3xl">Add a Card</h1>
            <Avatar className="w-32 h-32 border-2 border-gray-600 bg-gray-500 text-7xl text-gray-200">{user.firstName[0]}</Avatar>
          </div>
          <div className="flex flex-col items-center">
            <ProfileRecord icon={EmailIcon} content={user.email} />
          </div>
          <Cards
            number={cardState.number}
            name={cardState.name}
            expiry={cardState.expiry}
            cvc={cardState.cvc}
            focused={cardState.focus}
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-center fade-right-anim anim-500"
          >
            <div className="my-6" >
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="Credit Card Number"
                  variant="outlined"
                  className="w-full my-2"
                  {...register("number")}
                  error={errors.number ? true : false}
                  helperText={errors.number?.message}
                  value={cardState["number"]}
                  onChange={handleCardDataInputChange}
                  onFocus={handleCardDataInputFocus}
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="Name"
                  variant="outlined"
                  className="w-full my-2"
                  {...register("name")}
                  error={errors.name ? true : false}
                  helperText={errors.name?.message}
                  value={cardState["name"]}
                  onChange={handleCardDataInputChange}
                  onFocus={handleCardDataInputFocus}
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-wrap w-full md:w-[544px]">
                <TextField
                  label="Valid Thru"
                  variant="outlined"
                  className="w-[33%] my-2 mr-2"
                  {...register("expiry")}
                  error={errors.expiry ? true : false}
                  helperText={errors.expiry?.message}
                  value={cardState["expiry"]}
                  onChange={handleCardDataInputChange}
                  onFocus={handleCardDataInputFocus}
                  autoComplete="off"
                />

                <TextField
                  label="CVC"
                  variant="outlined"
                  className="w-[33%] my-2 mr-2"
                  {...register("cvc")}
                  error={errors.cvc ? true : false}
                  helperText={errors.cvc?.message}
                  value={cardState["cvc"]}
                  onChange={handleCardDataInputChange}
                  onFocus={handleCardDataInputFocus}
                  autoComplete="off"
                />
                <TextField
                  label="Zip Code"
                  variant="outlined"
                  className="w-[30%] my-2"
                  {...register("zip")}
                  error={errors.zip ? true : false}
                  helperText={errors.zip?.message}
                  value={cardState["zip"]}
                  onChange={handleCardDataInputChange}
                  onFocus={handleCardDataInputFocus}
                  autoComplete="off"
                />
              </div>
            </div>
            <Divider flexItem />

            <div className="flex justify-center">
              <Button
                type="submit"
                variant="contained"
                className="w-64 h-12 my-4 bg-primary font-bold tracking-widest"
                disabled={
                  isLoading || paymentloading
                }
              >
                {isLoading || paymentloading ? <ButtonLoader /> : "Save"}
              </Button>
              <Button
                variant="contained"
                color="error"
                className="w-36 h-12 ml-4 my-4 bg-red-500 text-lg tracking-widest"
                onClick={() => clickCancel()}
              >
                {"Cancel"}
              </Button>
            </div>
          </form>
        </Paper>
      </div>
      
    </main>
  ) : <></>;
}